<script setup>
const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  alt: {
    type: String,
    default: "",
  },
});

const attrs = useAttrs();

const imageHtmlEl = ref(null);
const loading = ref(false);

function watchProps() {
  watch(
    () => props.src,
    () => {
      if (props.src) {
        loading.value = true;
        setTimeout(() => {
          imageHtmlEl.value.src = props.src;
        });
      }
    },
    { immediate: true }
  );
}

onMounted(() => {
  watchProps();
});
</script>
<template>
  <div class="v-img">
    <img
      :ref="(el) => (imageHtmlEl = el)"
      :alt="props.alt"
      @loadstart="imageHtmlEl.display = 'none'"
      @loadeddata="
        loading = false;
        imageHtmlEl.display = 'block';
      "
      @error="loading = false"
      loading="lazy"
      class="img-el"
    />
    <v-loader-ring
      class="loader-el"
      v-if="loading && attrs.hasOwnProperty('loader')"
    ></v-loader-ring>
  </div>
</template>
<style scoped>
.v-img {
  position: relative;
  /* width: 207px;
  height: 207px; */
}
.img-el {
  position: relative;
  z-index: 1;
}
.loader-el {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 0;
}
</style>
